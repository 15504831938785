/* For use in src/lib/core/theming/_palette.scss */
$md-rbprimary: (
  50 : #fae0e8,
  100 : #f2b3c5,
  200 : #e9809e,
  300 : #e04d77,
  400 : #d92659,
  500 : #d2003c,
  600 : #cd0036,
  700 : #c7002e,
  800 : #c10027,
  900 : #b6001a,
  A100 : #ffdfe1,
  A200 : #ffacb2,
  A400 : #ff7984,
  A700 : #ff5f6c,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-rbblack: (
  50 : #e0e0e0,
  100 : #b3b3b3,
  200 : #808080,
  300 : #4d4d4d,
  400 : #262626,
  500 : #000000,
  600 : #000000,
  700 : #000000,
  800 : #000000,
  900 : #000000,
  A100 : #a6a6a6,
  A200 : #8c8c8c,
  A400 : #737373,
  A700 : #666666,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$md-rbsecondary: (
  50 : #e0e4e9,
  100 : #b3bbc8,
  200 : #808ea3,
  300 : #4d617e,
  400 : #263f62,
  500 : #001d46,
  600 : #001a3f,
  700 : #001537,
  800 : #00112f,
  900 : #000a20,
  A100 : #5d76ff,
  A200 : #2a4bff,
  A400 : #0027f6,
  A700 : #0023dc,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);




// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$rb-portal-primary: mat.define-palette($md-rbprimary);
$rb-portal-accent: mat.define-palette($md-rbblack);

// The warn palette is optional (defaults to red).
$rb-portal-warn: mat.define-palette($md-rbsecondary);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$rb-portal-theme: mat.define-light-theme((
  color: (
    primary: $rb-portal-primary,
    accent: $rb-portal-accent,
    warn: $rb-portal-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($rb-portal-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

//CUSTOM VARIABLES

:root{
  $primary-bg: #D2003C;
}

.mat-info-snackbar{
  background: #001d46;
}
.mat-error-snackbar{
  background: #D2003C;
}

// set font size of header equal to row
.mat-header-cell {
  font-size: 14px !important;
}

* {
  font-family: 'Archivo';
}